import {
  TableRow,
  TableHead,
  RoundLeft,
  RoundRight,
} from "../../components/Common/Styles/TableStyles";

import Button from "components/Common/Button/Button";
//import global context
import axiosInstance from "hooks/Common/axiosClient";

export function getApprovalHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Requestor</th>
        <th scope="col">Building ID(s)</th>
        <th scope="col">Room ID(s)</th>
        <th scope="col">Key Label(s)</th>
        <th scope="col">Reason</th>
        <th scope="col">Date</th>
        <th scope="col">Approve</th>

        <RoundRight scope="col">Deny</RoundRight>
      </tr>
    </TableHead>
  );
}

export async function getApprovalRows(
  uin,
  setApprovalRows,
  setAlert,
  setLoading
) {
  try {
    const response = await axiosInstance.get(
      `/api/approval/getAllApprovalsByUIN/${uin}/requestStatusId/1`
    );

    const approvals2 = response.data.result1;
    const approvals3 = response.data.result2;

    //first merge all approvals3 lines with the same accessRequestId
    let approvalsFinal = [];
    for (let i = 0; i < approvals2.length; i++) {
      let approval = approvals2[i];
      let approval3 = approvals3.filter(
        (approval3) => approval3.accessRequestId === approval.id
      );
      approval = { ...approval, ...approval3 };
      approvalsFinal.push(approval);
    }

    const rows = approvalsFinal.map((approval, index) => (
      <TableRow key={approval.id}>
        <td>{index + 1}</td>
        <td>{approval.accessHolderName}</td>
        <td>{getBuildingIds(approval)}</td>
        <td>{getRoomIds(approval)}</td>
        <td>{getKeyLabels(approval)}</td>
        <td>{approval.reasonComment}</td>
        {/* format date */}
        <td>{dateFormatted(approval.dateCreated)}</td>
        <td>
          <Button
            label="Approve"
            onClick={(e) => {
              approve(
                approval.id,
                e,
                uin,
                setApprovalRows,
                setAlert,
                setLoading
              );
            }}
          />
        </td>
        <td>
          <Button
            className="deny"
            label="Deny"
            onClick={(e) => {
              deny(approval.id, e, uin, setApprovalRows, setAlert, setLoading);
            }}
          />
        </td>
      </TableRow>
    ));

    setLoading(false);

    if (rows.length === 0) {
      setApprovalRows(<span>No Approvals Found</span>);
    } else {
      setApprovalRows(rows);
    }

    return rows;
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
}

const getBuildingIds = (approval) => {
  let i = 0;
  let returnString = "";
  while (approval[i] !== undefined) {
    if (approval[i].buildingId !== null) {
      //make sure the building id is not already in the string
      if (!returnString.includes(approval[i].buildingId)) {
        returnString += approval[i].buildingId + ", ";
      }
    }
    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const getRoomIds = (approval) => {
  let i = 0;
  let returnString = "";
  while (approval[i] !== undefined) {
    if (approval[i].roomId !== null) {
      //make sure the room id is not already in the string
      if (!returnString.includes(approval[i].roomId)) {
        returnString += approval[i].roomId + ", ";
      }
    }

    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const getKeyLabels = (approval) => {
  let i = 0;
  let returnString = "";
  while (approval[i] !== undefined) {
    if (approval[i].keyCode !== null) {
      //make sure the key code is not already in the string
      if (!returnString.includes(approval[i].keyCode)) {
        returnString += approval[i].keyCode + ", ";
      }
    }
    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const dateFormatted = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const AMorPM = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;

  return (
    month +
    "/" +
    day +
    "/" +
    year +
    " " +
    hours12 +
    ":" +
    minutes +
    " " +
    AMorPM
  );
};

const approve = async (id, e, uin, setApprovalRows, setAlert, setLoading) => {
  setLoading(true);
  const response = await axiosInstance.post(`/api/approval/approveRequest`, {
    id,
  });

  if (response.data.response[0].http_return === 200) {
    setAlert({
      visible: true,
      content: "Success, Request has been approved.",
      severity: "success",
      variant: "filled",
    });
    getApprovalRows(uin, setApprovalRows, setAlert, setLoading);
  } else {
    console.log("error" + response);
    alert("Error approving request");
  }
};

const deny = async (id, e, uin, setApprovalRows, setAlert, setLoading) => {
  setLoading(true);
  const response = await axiosInstance.post(`/api/approval/denyRequest`, {
    id,
  });

  if (response.data.response[0].http_return === 200) {
    setAlert({
      visible: true,
      content: "Success, Request has been denied.",
      severity: "success",
      variant: "filled",
    });
    getApprovalRows(uin, setApprovalRows, setAlert, setLoading);
  } else {
    console.log("error" + response);
    alert("Error denying request");
  }
};
