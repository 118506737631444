export const getStatusLabel = (status) => {
  switch (status) {
    case 1:
      return "Submitted";
    case 2:
      return "Approved";
    case 3:
      return "Filled";
    case 4:
      return "Delivered";
    case 5:
      return "Returned";
    case 6:
      return "Rejected";
    case 7:
      return "Cancelled";
    default:
      return "Unknown";
  }
};
