import {
  TableRow,
  TableHead,
  RoundLeft,
  RoundRight,
} from "../../components/Common/Styles/TableStyles";

import Button from "components/Common/Button/Button";
//import global context
import axiosInstance from "hooks/Axios/axiosClient";

import { getStatusLabel } from "hooks/Labels/getLabels";

export function getRequestHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">ID</th>
        <th scope="col">Status</th>
        <th scope="col">Building ID(s)</th>
        <th scope="col">Room ID(s)</th>
        <th scope="col">Key Label(s)</th>
        <th scope="col">Reason</th>
        <th scope="col">Date</th>
        <RoundRight scope="col">Action</RoundRight>
      </tr>
    </TableHead>
  );
}

export async function getRequestRows(
  uin,
  setRequestRows,
  setAlert,
  setRequestLoading
) {
  try {
    const response = await axiosInstance.get(
      `/api/requests/getAllKeyRequestByUIN/${uin}`
    );

    const requests2 = response.data.result1;
    const requests3 = response.data.result2;

    //first merge all requests3 lines with the same accessRequestId
    let requestsFinal = [];
    for (let i = 0; i < requests2.length; i++) {
      let request = requests2[i];
      let request3 = requests3.filter(
        (request3) => request3.accessRequestId === request.id
      );
      request = { ...request, ...request3 };
      requestsFinal.push(request);
    }

    const rows = requestsFinal.map((request, index) => (
      <TableRow key={request.id}>
        <td>{index + 1}</td>
        <td>{request.id}</td>
        <td>{getStatusLabel(request.requestStatusId)}</td>
        <td>{getBuildingIds(request)}</td>
        <td>{getRoomIds(request)}</td>
        <td>{getKeyLabels(request)}</td>
        <td>{request.reasonComment}</td>
        {/* format date */}
        <td>{dateFormatted(request.dateCreated)}</td>
        <td>
          {request.requestStatusId !== 7 ? (
            <Button
              label="Cancel"
              onClick={(e) => {
                cancel(
                  request.id,
                  e,
                  uin,
                  setRequestRows,
                  setAlert,
                  setRequestLoading
                );
              }}
            />
          ) : (
            <>
              <p>None Available</p>
            </>
          )}
        </td>
      </TableRow>
    ));

    setRequestLoading(false);

    if (rows.length === 0) {
      setRequestRows(<span>No Requests Found</span>);
    } else {
      setRequestRows(rows);
    }

    return rows;
  } catch (error) {
    console.log(error);
    setRequestLoading(false);
  }
}

const getBuildingIds = (request) => {
  let i = 0;
  let returnString = "";
  while (request[i] !== undefined) {
    if (request[i].buildingId !== null) {
      //make sure the building id is not already in the string
      if (!returnString.includes(request[i].buildingId)) {
        returnString += request[i].buildingId + ", ";
      }
    }
    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const getRoomIds = (request) => {
  let i = 0;
  let returnString = "";
  while (request[i] !== undefined) {
    if (request[i].roomId !== null) {
      //make sure the room id is not already in the string
      if (!returnString.includes(request[i].roomId)) {
        returnString += request[i].roomId + ", ";
      }
    }

    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const getKeyLabels = (request) => {
  let i = 0;
  let returnString = "";
  while (request[i] !== undefined) {
    if (request[i].keyCode !== null) {
      //make sure the key code is not already in the string
      if (!returnString.includes(request[i].keyCode)) {
        returnString += request[i].keyCode + ", ";
      }
    }
    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const dateFormatted = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const AMorPM = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;

  return (
    month +
    "/" +
    day +
    "/" +
    year +
    " " +
    hours12 +
    ":" +
    minutes +
    " " +
    AMorPM
  );
};

const cancel = async (
  id,
  e,
  uin,
  setRequestRows,
  setAlert,
  setRequestLoading
) => {
  setRequestLoading(true);
  const response = await axiosInstance.post(`/api/approval/cancelRequest`, {
    id,
  });

  if (response.data.response[0].http_return === 200) {
    setAlert({
      visible: true,
      content: "Success, Request has been cancelled.",
      severity: "success",
      variant: "filled",
    });
    getRequestRows(uin, setRequestRows, setAlert, setRequestLoading);
  } else {
    console.log("error" + response);
    alert("Error approving request");
  }
};
