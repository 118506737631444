import { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export function useGlobalContext() {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error(
      "useDefaultValue must be used within a DefaultValueProvider"
    );
  }
  return context;
}

export function GlobalProvider({ children }) {
  const [unitCacheData, setUnitCacheData] = useState({}); //cached array of filtered cache data
  const [departmentCacheData, setDepartmentCacheData] = useState({}); //cached college data has no filter,
  //we will still use the same technique so the code can follow the same template
  const [roomCacheData, setRoomCacheData] = useState({});
  const [electronicAccessControl, setElectronicAccessControl] = useState({});
  const [noteShareTypes, setNoteShareTypes] = useState({});
  const [userName, setUserName] = useState(null);
  const [roomDropdownCache, setRoomDropdownCache] = useState({});
  const [buildingCacheData, setBuildingCacheData] = useState({});
  const [svgCacheContent, setSvgCacheContent] = useState({});
  const [popupWindow, setPopupWindow] = useState(null);
  const [roomsSelected, setRoomsSelected] = useState([]);
  const [buildlingCacheData, setBuildlingCacheData] = useState({}); //cached array of filtered cache data
  const [buildingsSelected, setBuildingsSelected] = useState([]);
  const [keysSelected, setKeysSelected] = useState([]);
  const [keyCacheData, setKeyCacheData] = useState({});
  const [approvalRows, setApprovalRows] = useState(null);
  const [requestRows, setRequestRows] = useState(null);
  const [assignKeyRows, setAssignKeyRows] = useState(null);
  const [assignKeyLoading, setAssignKeyLoading] = useState(true);

  const [alert, setAlert] = useState({
    visible: false,
    content: null,
    severity: null,
    variant: false,
  });
  const [loading, setLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(true);

  const value = {
    unitCacheData,
    setUnitCacheData,
    departmentCacheData,
    setDepartmentCacheData,
    roomCacheData,
    setRoomCacheData,
    setElectronicAccessControl,
    electronicAccessControl,
    noteShareTypes,
    setNoteShareTypes,
    userName,
    setUserName,
    roomDropdownCache,
    setRoomDropdownCache,
    buildingCacheData,
    setBuildingCacheData,
    svgCacheContent,
    setSvgCacheContent,
    popupWindow,
    setPopupWindow,
    roomsSelected,
    setRoomsSelected,
    buildingsSelected,
    setBuildingsSelected,
    keysSelected,
    setKeysSelected,
    keyCacheData,
    setKeyCacheData,
    buildlingCacheData,
    setBuildlingCacheData,
    approvalRows,
    setApprovalRows,
    alert,
    setAlert,
    loading,
    setLoading,
    requestRows,
    setRequestRows,
    requestLoading,
    setRequestLoading,
    assignKeyRows,
    setAssignKeyRows,
    assignKeyLoading,
    setAssignKeyLoading,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}
