import React, { useEffect } from "react";
import { Modal } from "components/Common/Modal/Modal";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import Button from "components/Common/Button/Button";

import { ModalContainer } from "./MyTasksModal.styles";
import Input from "components/Common/Input/Input";

const MyTasksModal = ({ handleSubmit }) => {
  const {
    myTasksModalOpen,
    setMyTasksModalOpen,
    selectedRow,
    taskType,
    setTaskType,
    userName,
  } = useGlobalContext();

  useEffect(() => {}, [myTasksModalOpen, selectedRow]);
  return (
    <>
      {myTasksModalOpen && (
        <Modal
          content={
            <ModalContainer>
              <Input label="ID" data={selectedRow.id} readonly={true} />
              <Input
                label="Requestor"
                data={selectedRow?.accessHolderName}
                readonly={true}
              />
              <Input label="Date" data={selectedRow?.date} readonly={true} />
              {selectedRow.buildingIds && (
                <Input
                  label="Buildings"
                  data={selectedRow?.buildingIds}
                  readonly={true}
                />
              )}
              {selectedRow.roomIds && (
                <Input
                  label="Rooms"
                  data={selectedRow?.roomIds}
                  readonly={true}
                />
              )}
              {selectedRow.keyLabels && (
                <Input
                  label="Key labels"
                  data={selectedRow?.keyLabels}
                  readonly={true}
                />
              )}
              <Input
                label="Reason"
                data={
                  selectedRow?.reasonComment
                    ? selectedRow.reasonComment
                    : "No Reason Provided"
                }
                readonly={true}
              />
              Task type: {taskType}
            </ModalContainer>
          }
          setShowModal={setMyTasksModalOpen}
          heading={"Key Request Tasks"}
          saveButton={<Button label="Save" />}
          saveButtonDisabled={false}
          saveFunction={handleSubmit}
        />
      )}
    </>
  );
};

export default MyTasksModal;
