import axios from "axios";
import {
  PublicClientApplication,
  EventType,
  LogLevel,
} from "@azure/msal-browser";

const TENANT_ID = process.env.REACT_APP_TENANT_ID;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const config = {
  clientId: CLIENT_ID,
  authority: `https://login.microsoftonline.com/${TENANT_ID}`,
  redirectUri: "/",
  PostLogoutRedirectUri: "/",
  scopes: [`api://${CLIENT_ID}/access_as_user`],
  apiScopes: [`api://${CLIENT_ID}/.default`],
};

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_URL;

const GenerateBearer = async (instance) => {
  //prepare accesstoken from token cache
  try {
    if (instance) {
      const response = await instance.acquireTokenSilent({
        scopes: config.scopes,
        claims: sessionStorage.getItem("claimsChallenge")
          ? window.atob(sessionStorage.getItem("claimsChallenge"))
          : undefined,
      });
      const accessToken = response.accessToken;

      const bearer = `${accessToken}`;

      return bearer;
    }
  } catch (error) {
    console.log("GenerateBearer ERROR: " + error);
  }
};

class AxiosClientHelper {
  constructor() {
    this.MsalInstance = this.initPublicClientApplication();

    // [Debug Account]
    this.MsalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        this.MsalInstance.setActiveAccount(event.payload.account);
      }
    });

    this.axiosClient = axios.create({
      //baseURL: `https://keys-api.fs.illinois.edu`,
      baseURL: REACT_APP_API_ENDPOINT,
      timeout: 30000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    this.bearer = "";
  }

  async post(path, json) {
    const bearer = await GenerateBearer(this.MsalInstance);
    const response = await this.axiosClient.post(
      path,
      { json },
      { headers: { Authorization: "Bearer " + bearer } }
    );

    return this.handleClaimsChallenge(response);
  }

  async get(path) {
    if (!this.MsalInstance) return null;

    try {
      const bearer = await GenerateBearer(this.MsalInstance);
      const response = await this.axiosClient.get(path, {
        headers: { Authorization: "Bearer " + bearer },
      });

      return this.handleClaimsChallenge(response);
    } catch (error) {
      console.log("get ERROR: " + error);
      return null;
    }
  }

  async put(path, json) {
    if (!this.MsalInstance) return null;

    const bearer = await GenerateBearer(this.MsalInstance);
    const response = await this.axiosClient.put(
      path,
      { json },
      { headers: { Authorization: "Bearer " + bearer } }
    );
    return this.handleClaimsChallenge(response);
  }

  login() {
    if (!this.MsalInstance) return null;

    this.MsalInstance.loginRedirect({
      scopes: config.scopes,
    });
  }

  async refreshToken() {
    // todo: refresh token
    const response = await this.MsalInstance.acquireTokenSilent({
      ...config,
      scopes: ["user.read"],
      account: this.MsalInstance.getActiveAccount(),
      claims: sessionStorage.getItem("claimsChallenge")
        ? window.atob(sessionStorage.getItem("claimsChallenge"))
        : undefined,
    });

    const accessToken = response.accessToken;
    this.bearer = `${accessToken}`;
  }

  initPublicClientApplication = () =>
    new PublicClientApplication({
      auth: {
        ...config,
        clientCapabilities: ["CP1"],
      },
      cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPII) => {
            console.log(message);
          },
          logLevel: LogLevel.Warning,
        },
      },
    });

  handleClaimsChallenge = (response) => {
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      if (response.headers.get("www-authenticate")) {
        const authenticateHeader = response.headers.get("www-authenticate");

        const claimsChallenge = authenticateHeader
          .split(" ")
          .find((entry) => entry.includes("claims="))
          .split('claims="')[1]
          .split('",')[0];

        sessionStorage.setItem("claimsChallenge", claimsChallenge);
        return;
      }

      throw new Error(`Error ${response.status}`);
    } else {
      throw new Error(`Error ${response.status}`);
    }
  };

  getBearer() {
    return this.bearer;
  }
}

const axiosInstance = new AxiosClientHelper();

export default axiosInstance;
