import {
  TableRow,
  TableHead,
  RoundLeft,
  RoundRight,
} from "../../components/Common/Styles/TableStyles";

//import global context
import axiosInstance from "hooks/Axios/axiosClient";

import { getStatusLabel } from "hooks/Labels/getLabels";

import Select from "components/Common/Select/Select";

export function getMyTasksHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Requestor</th>
        <th scope="col">Building ID(s)</th>
        <th scope="col">Room ID(s)</th>
        <th scope="col">Key Label(s)</th>
        <th scope="col">Reason</th>
        <th scope="col">Date</th>
        <th scope="col">Status</th>
        <RoundRight scope="col">Action</RoundRight>
      </tr>
    </TableHead>
  );
}

export async function getMyTasksRows(
  uin,
  setMyTaskRows,
  setAlert,
  setMyTasksLoading,
  setMyTasksModalOpen,
  setSelectedRow,
  setTaskType
) {
  try {
    const response = await axiosInstance.get(
      `/api/approval/getAllTasksByUIN/${uin}`
    );

    const myTasks2 = response.data.result1;
    const myTasks3 = response.data.result2;

    if (!myTasks2 || !myTasks3) {
      setMyTaskRows(<span>No Tasks Found</span>);
      setMyTasksLoading(false);
      return;
    }

    //first merge all myTasks3 lines with the same accessRequestId
    let myTasksFinal = [];

    if (!myTasks2 || !myTasks3) {
      setMyTasksLoading(false);
      return;
    }
    for (let i = 0; i < myTasks2.length; i++) {
      let myTasks = myTasks2[i];
      let myTasks4 = myTasks3.filter(
        (myTasks3) => myTasks3.accessRequestId === myTasks.id
      );
      myTasks = { ...myTasks, ...myTasks4 };
      myTasksFinal.push(myTasks);
    }

    //iterate through each and get valid statuses to move too
    for (let i = 0; i < myTasksFinal.length; i++) {
      const response2 = await axiosInstance.get(
        `/api/approval/getAllStatusesByUINandRequestId/${uin}/${myTasksFinal[i].id}`
      );

      myTasksFinal[i].validStatuses = response2.data.results;
    }

    const rows = myTasksFinal.map((myTask, index) => (
      <TableRow key={myTask.id}>
        <td>{index + 1}</td>
        <td>{myTask.accessHolderName}</td>
        <td>{getBuildingIds(myTask)}</td>
        <td>{getRoomIds(myTask)}</td>
        <td>{getKeyLabels(myTask)}</td>
        <td>{myTask.reasonComment}</td>
        {/* format date */}
        <td>{dateFormatted(myTask.dateCreated)}</td>
        <td>{getStatusLabel(myTask.requestStatusId)}</td>
        {/* <td>
          <Button
            label="Assign"
            onClick={() => {
              setMyTasksModalOpen(true);
              myTask.buildingIds = getBuildingIds(myTask);
              myTask.roomIds = getRoomIds(myTask);
              myTask.keyLabels = getKeyLabels(myTask);
              myTask.date = dateFormatted(myTask.dateCreated);
              setSelectedRow(myTasks);
            }}
          />
        </td> */}
        <td>
          {getActionDropDown({
            validStatuses: myTask.validStatuses,
            task: myTask,
            setSelectedRow: setSelectedRow,
            setTaskType: setTaskType,
            setMyTasksModalOpen: setMyTasksModalOpen,
          })}
        </td>
      </TableRow>
    ));

    setMyTasksLoading(false);

    if (rows.length === 0) {
      setMyTaskRows(<span>No Tasks Found with current filters.</span>);
    } else {
      setMyTaskRows(rows);
    }

    console.log(rows);
    return rows;
  } catch (error) {
    console.log(error);
    setMyTasksLoading(false);
  }
}

const getBuildingIds = (myTasks) => {
  let i = 0;
  let returnString = "";
  while (myTasks[i] !== undefined) {
    if (myTasks[i].buildingId !== null) {
      //make sure the building id is not already in the string
      if (!returnString.includes(myTasks[i].buildingId)) {
        returnString += myTasks[i].buildingId + ", ";
      }
    }
    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const getRoomIds = (myTasks) => {
  let i = 0;
  let returnString = "";
  while (myTasks[i] !== undefined) {
    if (myTasks[i].roomId !== null) {
      //make sure the room id is not already in the string
      if (!returnString.includes(myTasks[i].roomId)) {
        returnString += myTasks[i].roomId + ", ";
      }
    }

    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const getKeyLabels = (myTasks) => {
  let i = 0;
  let returnString = "";
  while (myTasks[i] !== undefined) {
    if (myTasks[i].keyCode !== null) {
      //make sure the key code is not already in the string
      if (!returnString.includes(myTasks[i].keyCode)) {
        returnString += myTasks[i].keyCode + ", ";
      }
    }
    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const dateFormatted = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const AMorPM = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;

  return (
    month +
    "/" +
    day +
    "/" +
    year +
    " " +
    hours12 +
    ":" +
    minutes +
    " " +
    AMorPM
  );
};

const cancel = async (
  id,
  e,
  uin,
  setRequestRows,
  setAlert,
  setRequestLoading
) => {
  setRequestLoading(true);
  const response = await axiosInstance.post(`/api/approval/cancelRequest`, {
    id,
  });

  if (response.data.response[0].http_return === 200) {
    setAlert({
      visible: true,
      content: "Success, Request has been cancelled.",
      severity: "success",
      variant: "filled",
    });
    getRequestRows(uin, setRequestRows, setAlert, setRequestLoading);
  } else {
    console.log("error" + response);
    alert("Error approving request");
  }
};

const getActionDropDown = ({
  validStatuses,
  task,
  setSelectedRow,
  setTaskType,
  setMyTasksModalOpen,
}) => {
  let statusOptions = [];

  if (!validStatuses) {
    return <span>No Actions Available</span>;
  }

  for (let i = 0; i < validStatuses.length; i++) {
    //build out the options for the select

    //if name attribute is "Submitted" then add "Approve" to the options
    if (validStatuses[i].name === "Approved") {
      statusOptions.push({ id: 2, name: "Assign Keys" });
      statusOptions.push({ id: 3, name: "Fill Keys" });
    } else if (validStatuses[i].name === "Filled") {
      statusOptions.push({ id: 4, name: "Deliver Keys" });
    } else if (validStatuses[i].name === "Rejected") {
      statusOptions.push({ id: 5, name: "Reject" });
    } else if (validStatuses[i].name === "Cancelled") {
      statusOptions.push({ id: 6, name: "Cancel" });
    }
  }

  return (
    <Select
      options={statusOptions}
      optionsheader="Select Action"
      onChange={(e) => {
        setSelectedRow(task);
        console.log(e);
        setTaskType(e);
        setMyTasksModalOpen(true);
      }}
    />
  );
};
