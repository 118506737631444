import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  label {
    font-weight: 700;
  }

  h3 {
    margin-top: 1rem;
  }
`;
