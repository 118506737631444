import React from "react";
import { Container } from "./Links.styles";
import Card from "../../Common/Card/Card";
import Button from "../../Common/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorOpen,
  faUser,
  faKey,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

const Links = () => {
  const rightIcon = <FontAwesomeIcon icon={faAngleRight} />;
  const Link = [
    {
      Link: "Keys",
      to: "/keys",
      icon: <FontAwesomeIcon icon={faKey} />,
    },
    {
      Link: "Rooms",
      to: "/rooms",
      icon: <FontAwesomeIcon icon={faDoorOpen} />,
    },
    {
      Link: "Key Request",
      to: "/requestkeys",
      icon: <FontAwesomeIcon icon={faUser} />,
    },
  ];

  const onClick = (link) => {
    return () => {
      window.location.href = link;
    };
  };

  const LinkButtons = Link.map((link, index) => {
    return (
      <Button
        key={index}
        onClick={onClick(link.to)}
        label={
          <>
            {link.icon}
            <span>{link.Link}</span>
            {rightIcon}
          </>
        }
      ></Button>
    );
  });
  return (
    <Container>
      <Card
        padding="1.5rem"
        width="100%"
        content={
          <>
            <p className="tertiaryTitle">Quick Access Menu</p>
            {LinkButtons}
          </>
        }
      ></Card>
    </Container>
  );
};

export default Links;
