import { useEffect } from "react";
import RequestsTable from "../MyRequestsTable/MyRequestsTable";
import Alert from "@mui/material/Alert";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";

const Requests = () => {
  const { alert, setAlert } = useGlobalContext();

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({
          visible: false,
          content: null,
          severity: null,
          variant: false,
        });
      }, 3000);
    }
  }, [alert]);

  return (
    <>
      {alert.visible ? (
        <Alert
          severity={alert.severity}
          variant={alert.variant}
          style={{
            width: "fit-content",
            margin: "0 auto",
          }}
        >
          {alert.content}
        </Alert>
      ) : null}
      <br />
      <RequestsTable />
    </>
  );
};
export default Requests;
