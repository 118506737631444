import { useState } from "react";
import { MenuDiv, Container } from "./Menu.styles";
import { NavLink } from "react-router-dom";
import Icon from "../Icon/Icon";

const Menu = () => {
  const menu = [
    {
      id: 1,
      label: "Keys",
      url: "/keys",
      childItems: {
        nodes: [],
      },
    },
    {
      id: 2,
      label: "Rooms",
      url: "/rooms",
      childItems: {
        nodes: [],
      },
    },
    {
      id: 3,
      label: "Request Keys",
      url: "/requestkeys",
      childItems: {
        nodes: [],
      },
    },
    {
      id: 4,
      label: "Admin",
      url: "",
      childItems: {
        nodes: [
          { id: 5, label: "My Approvals", url: "/approvals" },
          { id: 6, label: "My Assignments", url: "/assignkeys" },
          { id: 7, label: "Uploads", url: "/uploads" },
        ],
      },
    },
    {
      id: 8,
      label: "User",
      url: "",
      childItems: {
        nodes: [
          { id: 9, label: "My Requests", url: "/myrequests" },
          { id: 10, label: "Settings", url: "" },
          { id: 11, label: "Logout", url: "/logout" },
        ],
      },
    },
  ];
  let i = 0;

  return (
    <MenuDiv>
      <Container>
        <ul>{menu.map((mainItem) => ClickHandler(mainItem, i))}</ul>
      </Container>
    </MenuDiv>
  );
};

function removeClass(className) {
  const elements = document.querySelectorAll(`.${className}`);

  elements.forEach((element) => {
    element.classList.remove(className);
  });
}

function ClickHandler(mainItem, i) {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    removeClass("nav-active");

    setClick(!click);
  };

  function keydown(event) {
    if (event.keyCode === 13) {
      removeClass("nav-active");
      setClick(!click);
    }
  }

  return (
    // build menu item and child menu
    <li key={mainItem.id}>
      {mainItem.childItems.nodes.length !== 0 ? (
        <button
          href={null}
          onClick={handleClick}
          onKeyDown={keydown}
          tabIndex={i}
          className={click ? "nav nav-active" : "nav"}
          style={{ color: "var(--illiniDarkBlue" }}
        >
          {mainItem.label}

          {mainItem.childItems.nodes.length !== 0 && click ? (
            <div>
              <Icon className="icon" category="Up Arrow" />
            </div>
          ) : (
            <div>
              <Icon className="icon" category="Down Arrow" />
            </div>
          )}

          {mainItem.childItems.nodes.length !== 0 ? (
            <ul className={click ? "activeUL" : ""}>
              {mainItem.childItems.nodes.map((childItem) => {
                return (
                  <li key={childItem.id}>
                    {childItem.url.charAt(0) === "/" ? (
                      <NavLink
                        to={childItem.url}
                        activeclassname="nav-active"
                        className="secondaryLink"
                      >
                        {childItem.label}
                      </NavLink>
                    ) : (
                      <NavLink to={childItem.url} className="secondaryLink">
                        {childItem.label}
                      </NavLink>
                    )}
                  </li>
                );
              })}
            </ul>
          ) : null}
        </button>
      ) : (
        <NavLink className="mainLink" index={mainItem.id} to={mainItem.url}>
          {mainItem.label}
        </NavLink>
      )}
    </li>
  );
}

export default Menu;
