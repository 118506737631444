import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { nameFormat } from "hooks/Common/FormatMsalName";
import Links from "./Links/Links";
import { useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";

const Home = () => {
  //lines to get formatted names
  const { accounts } = useMsal();
  const { name } = accounts[0];
  const formattedName = "Welcome, " + nameFormat(name);

  //missing page functionality
  const [missingPage, setMissingPage] = useState(false);
  const location = useLocation();

  const missingPageFound = async () => {
    await setMissingPage(true);
    location.state = {};
  };

  if (location.state?.missingPage && !missingPage) {
    missingPageFound();
  }

  useEffect(() => {
    console.log("missing page", missingPage);
    if (missingPage) {
      console.log("setting timeout");
      setTimeout(() => {
        setMissingPage(false);
      }, 5000);
    }
  }, [missingPage]);
  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}
      >
        {missingPage && (
          <>
            <Alert
              severity="error"
              variant="filled"
              style={{
                width: "fit-content",
                margin: "0 auto",
              }}
            >
              The page you are looking for does not exist, you have been
              redirected to the home page.
            </Alert>
          </>
        )}
        <div
          style={{
            width: "1180px",
            margin: "0 auto",
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          <h2>{formattedName}</h2>
        </div>
        <Links />
      </div>
    </div>
  );
};

export default Home;
