import { useEffect, useState } from "react";
import MyTasksTable from "../MyTasksTable/MyTasksTable";

import Alert from "@mui/material/Alert";
import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import MyTasksModal from "../MyTasksModal/MyTasksModal";

const MyTasks = () => {
  const { alert, setAlert } = useGlobalContext();

  useEffect(() => {
    if (alert.visible) {
      setTimeout(() => {
        setAlert({
          visible: false,
          content: null,
          severity: null,
          variant: false,
        });
      }, 3000);
    }
  }, [alert]);

  const handleSubmit = () => {
    console.log("handleSubmit");
  };

  return (
    <>
      {alert.visible ? (
        <Alert
          severity={alert.severity}
          variant={alert.variant}
          style={{
            width: "fit-content",
            margin: "0 auto",
          }}
        >
          {alert.content}
        </Alert>
      ) : null}

      <MyTasksModal handleSubmit={handleSubmit} />

      <br />
      <MyTasksTable />
    </>
  );
};
export default MyTasks;
