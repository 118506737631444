import {
  TableRow,
  TableHead,
  RoundLeft,
  RoundRight,
} from "../../components/Common/Styles/TableStyles";

import Button from "components/Common/Button/Button";
//import global context
import axiosInstance from "hooks/Common/axiosClient";

export function getAssignKeyHeader() {
  return (
    <TableHead>
      <tr>
        <RoundLeft scope="col" width="2%">
          n
        </RoundLeft>
        <th scope="col">Requestor</th>
        <th scope="col">Building ID(s)</th>
        <th scope="col">Room ID(s)</th>
        <th scope="col">Key Label(s)</th>
        <th scope="col">Reason</th>
        <th scope="col">Date</th>
        <th scope="col">Assign</th>

        <RoundRight scope="col">Cancel</RoundRight>
      </tr>
    </TableHead>
  );
}

export async function getAssignKeyRows(
  uin,
  setAssignKeyRows,
  setAlert,
  setAssignKeyLoading
) {
  try {
    const response = await axiosInstance.get(
      `/api/approval/getAllApprovalsByUIN/${uin}/requestStatusId/1`
    );

    const assignkeys2 = response.data.result1;
    const assignkeys3 = response.data.result2;

    //first merge all assignkeys3 lines with the same accessRequestId
    let assignkeysFinal = [];
    for (let i = 0; i < assignkeys2.length; i++) {
      let assignkey = assignkeys2[i];
      let assignkey3 = assignkeys3.filter(
        (assignkey3) => assignkey3.accessRequestId === assignkey.id
      );
      assignkey = { ...assignkey, ...assignkey3 };
      assignkeysFinal.push(assignkey);
    }

    const rows = assignkeysFinal.map((assignkey, index) => (
      <TableRow key={assignkey.id}>
        <td>{index + 1}</td>
        <td>{assignkey.accessHolderName}</td>
        <td>{getBuildingIds(assignkey)}</td>
        <td>{getRoomIds(assignkey)}</td>
        <td>{getKeyLabels(assignkey)}</td>
        <td>{assignkey.reasonComment}</td>
        {/* format date */}
        <td>{dateFormatted(assignkey.dateCreated)}</td>
        <td>
          <Button
            label="Assign"
            onClick={(e) => {
              console.log("Assigning key");
            }}
          />
        </td>
        <td>
          <Button
            className="deny"
            label="Cancel"
            onClick={(e) => {
              cancel(
                assignkey.id,
                e,
                uin,
                setAssignKeyRows,
                setAlert,
                setAssignKeyLoading
              );
            }}
          />
        </td>
      </TableRow>
    ));

    setAssignKeyLoading(false);

    if (rows.length === 0) {
      setAssignKeyRows(<span>No AssignKeys Found</span>);
    } else {
      setAssignKeyRows(rows);
    }

    return rows;
  } catch (error) {
    console.log(error);
    setAssignKeyLoading(false);
  }
}

const getBuildingIds = (assignkey) => {
  let i = 0;
  let returnString = "";
  while (assignkey[i] !== undefined) {
    if (assignkey[i].buildingId !== null) {
      //make sure the building id is not already in the string
      if (!returnString.includes(assignkey[i].buildingId)) {
        returnString += assignkey[i].buildingId + ", ";
      }
    }
    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const getRoomIds = (assignkey) => {
  let i = 0;
  let returnString = "";
  while (assignkey[i] !== undefined) {
    if (assignkey[i].roomId !== null) {
      //make sure the room id is not already in the string
      if (!returnString.includes(assignkey[i].roomId)) {
        returnString += assignkey[i].roomId + ", ";
      }
    }

    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const getKeyLabels = (assignkey) => {
  let i = 0;
  let returnString = "";
  while (assignkey[i] !== undefined) {
    if (assignkey[i].keyCode !== null) {
      //make sure the key code is not already in the string
      if (!returnString.includes(assignkey[i].keyCode)) {
        returnString += assignkey[i].keyCode + ", ";
      }
    }
    i++;
  }

  //trim off last comma
  returnString = returnString.substring(0, returnString.length - 2);

  if (returnString === "") {
    returnString = "N/A";
  }
  return returnString;
};

const dateFormatted = (date) => {
  const dateObj = new Date(date);
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const AMorPM = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12;

  return (
    month +
    "/" +
    day +
    "/" +
    year +
    " " +
    hours12 +
    ":" +
    minutes +
    " " +
    AMorPM
  );
};

const cancel = async (
  id,
  e,
  uin,
  setRequestRows,
  setAlert,
  setRequestLoading
) => {
  setRequestLoading(true);
  const response = await axiosInstance.post(`/api/approval/cancelRequest`, {
    id,
  });

  if (response.data.response[0].http_return === 200) {
    setAlert({
      visible: true,
      content: "Success, Request has been cancelled.",
      severity: "success",
      variant: "filled",
    });
    getRequestRows(uin, setRequestRows, setAlert, setRequestLoading);
  } else {
    console.log("error" + response);
    alert("Error approving request");
  }
};
