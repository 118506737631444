import React, { useState, useEffect } from "react";
import { StyledTable } from "components/Common/Styles/TableStyles";

import {
  getMyTasksHeader,
  getMyTasksRows,
} from "hooks/MyTasks/MyTasksTableFunctions";

import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import CircularProgress from "@mui/material/CircularProgress";
import { useMsal } from "@azure/msal-react";

const MyTasksTable = () => {
  const [header, setHeader] = useState(null);

  const { accounts } = useMsal();

  const userName = accounts[0]?.idTokenClaims;

  const {
    myTaskRows,
    setMyTaskRows,
    setAlert,
    myTasksLoading,
    setMyTasksLoading,
    setMyTasksModalOpen,
    setSelectedRow,
  } = useGlobalContext();

  if (userName?.uin && myTasksLoading) {
    getMyTasksRows(
      userName.uin,
      setMyTaskRows,
      setAlert,
      setMyTasksLoading,
      setMyTasksModalOpen,
      setSelectedRow
    );
  }

  if (header === null) {
    const header = getMyTasksHeader();
    setHeader(header);
  }

  useEffect(() => {}, [myTaskRows, myTasksLoading]);
  return (
    <>
      <div style={{ position: "relative", height: "fit-content" }}>
        {myTasksLoading ? (
          <>
            <div
              style={{
                position: "absolute",
                top: "0%",
                left: "0%",
                opacity: "1",
                width: "100%",
                height: "100%",
                backgroundColor: "var(--darkGrey)",
                opacity: "0.5",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  marginTop: "3rem",
                }}
              >
                <CircularProgress />
              </div>
            </div>
            <StyledTable>
              {header}
              {myTaskRows}
            </StyledTable>
          </>
        ) : (
          <StyledTable>
            {header}
            <tbody>{myTaskRows}</tbody>
          </StyledTable>
        )}
      </div>
    </>
  );
};

export default MyTasksTable;
