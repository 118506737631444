import axios from "axios";
import { msalInstance } from "index";

const requestInterceptor = async (config) => {
  try {
    const scopes = msalInstance?.controller?.config?.auth?.scopes;

    const accounts = msalInstance.getAllAccounts();

    if (accounts.length === 0) {
      throw new Error("No accounts found");
    }

    const response = await msalInstance.acquireTokenSilent({
      scopes: scopes,
      account: accounts[0],
      claims: sessionStorage.getItem("claimsChallenge")
        ? window.atob(sessionStorage.getItem("claimsChallenge"))
        : undefined,
    });

    config.headers.Authorization = `Bearer ${response.accessToken}`;
  } catch (error) {
    console.error("Error acquiring token silently", error);
  }

  return config;
};

axios.interceptors.request.use(requestInterceptor, (error) =>
  Promise.reject(error)
);

export default requestInterceptor;
