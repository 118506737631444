import React, { useState, useEffect } from "react";
import { StyledTable } from "components/Common/Styles/TableStyles";

import {
  getAssignKeyHeader,
  getAssignKeyRows,
} from "hooks/AssignKeys/AssignKeyTableFunctions";

import { useGlobalContext } from "components/Common/GlobalContext/GlobalContext";
import CircularProgress from "@mui/material/CircularProgress";

const AssignKeysTable = () => {
  const [header, setHeader] = useState(null);

  const {
    userName,
    assignKeyRows,
    setAssignKeyRows,
    setAlert,
    assignKeyLoading,
    setAssignKeyLoading,
  } = useGlobalContext();

  if (userName?.uin && assignKeyLoading) {
    getAssignKeyRows(
      userName.uin,
      setAssignKeyRows,
      setAlert,
      setAssignKeyLoading
    );
  }

  if (header === null) {
    const header = getAssignKeyHeader();
    setHeader(header);
  }

  useEffect(() => {}, [assignKeyRows, assignKeyLoading]);
  return (
    <>
      <div style={{ position: "relative", height: "fit-content" }}>
        {assignKeyLoading ? (
          <>
            <div
              style={{
                position: "absolute",
                top: "0%",
                left: "0%",
                opacity: "1",
                width: "100%",
                height: "100%",
                backgroundColor: "var(--darkGrey)",
                opacity: "0.5",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                  marginTop: "3rem",
                }}
              >
                <CircularProgress />
              </div>
            </div>
            <StyledTable>
              {header}
              {assignKeyRows}
            </StyledTable>
          </>
        ) : (
          <StyledTable>
            {header}
            <tbody>{assignKeyRows}</tbody>
          </StyledTable>
        )}
      </div>
    </>
  );
};

export default AssignKeysTable;
